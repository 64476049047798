import { Content } from "antd/lib/layout/layout";
import React from "react";

import { LayoutGeneral } from "src/layout/LayoutGeneral";

import "src/components/products/style.scss";
import "src/components/products/thermoPlus/style.scss";
import ThermoPlusFichaTecnica from "src/components/products/thermoPlus/FichaTecnica";

const ThermoPlus = (props) => (
  <LayoutGeneral
    {...{ ...props, productName: "ThermoPlus", showPageTitle: false }}
  >
    <Content className="product thermoPlus fichaTecnica">
      <ThermoPlusFichaTecnica />
    </Content>
  </LayoutGeneral>
);

export default ThermoPlus;
