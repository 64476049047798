import React from "react";

import { Divider, List } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { Link } from "gatsby";

export const ThermoPlusFichaTecnica = () => {
  const data = [
    {
      content: (
        <>
          <b className="paragraphTitle">Composición</b>
          Dispersión de poliacrilato, dióxido de titanio, caolín, carbonato de
          calcio, silicatos, agua, propilenglicol, celulosa, conservantes
          <b className="paragraphTitle">Grupo de productos/Código SIG</b>
          BSW20 Es de aplicación la información contenida en la ficha de datos
          de seguridad vigente.
          <b className="paragraphTitle">Áreas de aplicación</b>
          ClimateCoating ThermoPlus es un revestimiento resistente adecuado para
          casi todas las superficies interiores. La especial composición de
          ClimateCoating ThermoPlus proporciona una amplia gama de áreas de
          aplicación energética para generar un escudo térmico tanto en verano
          como en invierno.
          <b className="paragraphTitle">Propiedades específicas del producto</b>
          Permeabilidad variable al vapor de agua, bajas emisiones, libre de
          disolventes, reflectante a la radiación solar, muy alta resistencia
          del color, genera condiciones de confort térmico, regulador de la
          humedad en soportes permeables al vapor y con capacidad de sorción
          <b className="paragraphTitle">Datos del material</b>
          <ul>
            <li>
              Valores sd según DIN 52615
              <br />
              zona seca: sd = 1,7
              <br />
              zona húmeda: sd = 0,02
            </li>

            <li>Densidad 1,12 kg/dm3 según ISO 2811-1</li>
            <li>
              Valor PH: 8,00 – 9,20 a 20 °C Comportamiento frente al fuego:
              B-s1, d0
            </li>
            <li>Resistencia química: disponible bajo consulta</li>
            <li>Brillo: 0,4 según DIN ISO 2813</li>
            <li>
              Resistencia a la abrasión húmeda: clase 3 según DIN-EN 13300
            </li>
            <li>
              Relación contraste / poder de cubrición: clase 2 según DIN-EN
              13300
            </li>
          </ul>
          <b className="paragraphTitle">Reglamento de COV (CE)</b>
          Valor límite de 01.01.2010 material de revestimiento mate (Prod.-Cat.
          a) para paredes y techos interiores = 30 g/l. Este producto contiene{" "}
          {"<3 "} g/l VOC.
          <b className="paragraphTitle">Superficies de aplicación</b>
          ClimateCoating ThermoPlus se puede aplicar a sobre todas las
          superficies estables, limpias, secas, libres de óxido y de grasa, como
          guarnecido o enfoscado viejo y nuevo y superficies firmes previamente
          pintadas. Se recomienda una superficie permeable para garantizar las
          propiedades energéticas del producto..
        </>
      ),
    },
    {
      content: (
        <>
          <b className="paragraphTitle"> Preparación del soporte</b>
          <ul>
            <li>Sintéticas: comprobar la capacidad de adherencia</li>
            <li>
              Metales: dependiendo del metal y la tensión, aplicar imprimación
              RustPrimer o ZincPrimer
            </li>
            <li>
              Revestimiento anterior no fiable o adecuado: dependiendo del
              revestimiento, eliminar por lavado, limpieza ácida, limpiador de
              humedad caliente de alta presión Superficies que interfieren en la
              adherencia: lavar, lijar
            </li>
            <li>Sustratos absorbentes: aplicar imprimación FixPlus</li>
          </ul>
          <b className="paragraphTitle"> Aplicación</b>
          El producto se puede aplicar mediante brocha, rodillo o método de
          pulverización. Para la primera aplicación se requieren dos capas.
          ClimateCoating ThermoPlus se suministra listo para su uso. En caso de
          aplicación mediante equipos de pulverización, se deben seguir las
          instrucciones del fabricante del equipo. Para su aplicación en
          superficies muy rugosas o mediante el uso de equipos de pulverización,
          la consistencia del producto se puede regular añadiendo una pequeña
          cantidad de agua. Durante la aplicación, es importante mantener la
          temperatura del aire y del objeto a una temperatura mínima de 5°C.
          Esto aplica igualmente al período de secado. Remueva con un aparato
          mezclador antes de la aplicación o después de largas interrupciones
          del trabajo. Permita un período de secado de 6 horas entre dos capas
          individuales.
          <b className="paragraphTitle">Consumo</b>
          El rendimiento de ClimateCoating ThermoPlus es de aproximadamente 330
          ml/m2, calculado para dos capas, sobre superficies lisas de baja
          porosidad. La capacidad de esparcimiento variará según la estructura y
          la porosidad de la superficie. En caso de duda, primero se debe
          aplicar sobre un área de prueba pequeña. Para optimizar la eficiencia
          energética en la protección térmica en condiciones de verano, se
          requiere un mayor espesor de capa dependiendo del clima y la
          superficie.
          <b className="paragraphTitle">Limpieza de herramientas</b>
          Los rodillos, brochas y equipos de pulverización deben lavarse con
          agua a fondo inmediatamente después de su uso. Volumen de suministro
          2.8 litros; 5 litros; 10 litros; 12,5 litros Otros tamaños de envase
          disponibles bajo petición.
        </>
      ),
    },
    {
      content: (
        <>
          <b className="paragraphTitle">Colores</b>
          ClimateCoating ThermoPlus se puede tintar según los sistemas de color
          NCS, RAL y ClimateCoating Edition 1. Por favor, compruebe la tonalidad
          del color antes de la aplicación.
          <b className="paragraphTitle">Almacenamiento y transporte</b>
          ClimateCoating ThermoPlus debe almacenarse y transportarse en ambiente
          frío, pero protegido contra heladas. Una vez abiertos, los recipientes
          deben ser cerrados firmemente, y aplicado el producto lo antes
          posible. Almacenable a partir de la fecha de fabricación durante 12
          meses.
          <b className="paragraphTitle">Gestión de residuos</b>
          No vacíe los restos de producto en la red de evacuación, en cursos de
          agua o el terreno. Sólo los recipientes completamente vaciados deben
          ser desechados para su reciclaje. Por favor, deposite los productos
          parcialmente utilizados en un punto de gestión de residuos autorizado.
          Código de residuos no peligrosos según la lista de residuos de la UE:
          080120
          <b className="paragraphTitle">Aviso de seguridad</b>
          El contenido de la ficha técnica no manifiesta ninguna relación
          jurídica contractual. En cualquier caso, el aplicador/cliente tiene
          que considerar si el material es utilizable en condiciones prácticas
          antes de la aplicación. No permitir la entrada en la red de
          evacuación, en cursos de agua o el terreno. Mantener alejado de
          alimentos, bebidas y piensos. Mantener fuera del alcance de los niños.
          Proteger las superficies que no deben tratarse tomando las medidas
          adecuadas. Evitar el contacto con la piel y los ojos. Cuando se
          aplique con el método de pulverización, evitar la inhalación de polvo
          emergente. La información anteriormente mencionada dejará de ser
          válida al publicarse una nueva ficha técnica consecuencia de la mejora
          del producto. La información proporcionada fue verificada por
          ClimateCoating GmbH en laboratorio y en la práctica como valores de
          referencia. La información del producto se ofrece en base a nuestro
          mejor conocimiento y de acuerdo con el estado actual de la tecnología.
          Influencias ambientales, materiales, equipos de aplicación y métodos
          de aplicación están fuera de nuestro control y, por lo tanto, fuera de
          nuestra responsabilidad. ClimateCoating©2019
        </>
      ),
    },
  ];

  return (
    <>
      <div className="linkBack">
        <Link to="/productos/thermoplus/">
          <RollbackOutlined />{" "}
          <span className="colored linkText">Ir a ThermoPlus</span>
        </Link>
      </div>{" "}
      <div className="productDocHeader">
        <h1 className="centered">Ficha Técnica ThermoPlus</h1>
      </div>
      <Divider />
      <div style={{ marginBottom: "50px" }}>
        <List
          grid={{ gutter: 24, column: 3 }}
          dataSource={data}
          renderItem={({ content }) => <List.Item>{content}</List.Item>}
        />
      </div>
    </>
  );
};

export default ThermoPlusFichaTecnica;
